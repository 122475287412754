.additional-content-ac {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 60px;
  margin: 0 auto;
  max-width: 1400px; /* Adjusted for larger screens */
  margin-bottom: 80px;
  margin-top: 60px;
}
.youtube-subscribe-section {
  background: #f9f9f9;
  border-radius: 12px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  padding: 30px;
  text-align: center;
  margin: 30px 0;
}

.youtube-subscribe-section h2 {
  font-size: 28px;
  color: #ff0000; /* YouTube red */
  margin-bottom: 10px;
}

.youtube-subscribe-section p {
  font-size: 18px;
  color: #333;
  margin-bottom: 20px;
}

.youtube-button {
  display: inline-block;
  padding: 12px 25px;
  background: #ff0000; /* YouTube red */
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  text-decoration: none;
  border-radius: 8px;
  transition: background 0.3s;
}

.youtube-button:hover {
  background: #cc0000;
}

.promo-section-pg {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: #f9f9f9;  #05192D;*/
  background-color: #fff;
  padding: 30px; /* Increased padding for larger screens */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  margin: 0 auto;
  max-width: 1200px; /* Adjusted for larger screens */
  margin-top: 80px;
  width: 100%;
  flex-wrap: wrap;
  
}

.promo-content-pg {
  flex: 1;
  margin-left: 30px; /* Increased margin for larger screens */
  max-width: 55%; /* Adjusted for larger screens */
color: #343a40;
  
}

.promo-content-pg ul li {
  font-size: 1.2rem; /* Slightly larger font size */
  margin-bottom: 10px;
}

.roadmap-image-pg {
  width: 40%;
  max-width: 600px;
  height: auto;
  margin-right: 30px; /* Increased margin for larger screens */
  border-radius: 7px;
}

.promo-buttons-pg {
  display: flex;
  gap: 30px; /* Increased gap between buttons for larger screens */
  justify-content: center;
  margin-top: 40px;
}

.promo-button-pg {
  padding: 15px 30px; /* Larger padding for buttons on larger screens */
  font-size: 1.2rem; /* Larger font size for buttons */
  border: none;
  border-radius: 4px;
  cursor: pointer;

}
.ask-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px 0; /* Increased margin for better spacing */
    transform: scale(1.1); /* 1.5 means 150% of its original size */
    transition: transform 0.3s ease; /* smooth transition */
  }

.ask-box {
  display: flex;
  align-items: center;
  gap: 20px; /* Increased gap for better spacing */
  width: 100%;
  max-width: 500px; /* Increased max-width for a larger box */
  height: 200px; /* Increased height of the box */
  
}

.ask-input {
  padding: 25px; /* Increased padding for larger input */
  border-radius: 7px; /* Increased border-radius for smoother edges */
  border: 1px solid #ccc;
  flex: 1;
  font-size: 22px; /* Increased font size */
  transition: box-shadow 0.3s ease;
  box-shadow: 0 0 15px rgba(255, 145, 0, 0.7);
  background-color:rgb(6, 6, 32);
  color: #fffcfc;
}

.ask-input:focus {
  box-shadow: 0 0 15px rgba(0, 150, 255, 0.9); /* Enhanced box-shadow for focus effect */
  outline: none;
}

.ask-submit {
  padding: 10px 20px; /* Increased padding for a larger button */
  border: none;
  border-radius: 7px; /* Increased border-radius for smoother edges */
  background-color:rgb(6, 6, 32);
  color: #fffcfc;
  cursor: pointer;
  margin-top: -40px;
  font-size: 22px; /* Increased font size */
}

.ask-submit:hover {
  background: rgb(11, 11, 70);
}


.demo-button-pg {
  background-color: rgb(255, 147, 30);
  color: black;
}
.status-message {
  font-weight: bold;
  margin-top: 10px;
}

.status-message.error {
  color: red; /* For error messages */
}

.status-message.success {
  color: green; /* For success message */
}

.status-message.warning {
  color: red; /* For login warning */
}

.status-message {
  margin-top: 10px;
  font-size: 14px;
  color: #28a745; /* Green for success */
  text-align: center;
}



.get-started-button-pg {
  background-color: #343a40;
  color: white;
}

.content-item-ac {
  display: flex;
  align-items: center;
  gap: 50px; /* Increased gap for larger screens */
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 1400px; /* Adjusted for larger screens */
  margin: 0 auto;
}

.content-image-ac {
  width: 500px; /* Larger image size for larger screens */
  height: auto;
}

.content-text-ac {
  max-width: 800px;
  font-size: 2.5rem; /* Larger text for larger screens */
  line-height: 1.8;
}

.content-text-ac h3 {
  font-size: 2.3rem; /* Larger heading for larger screens */
  font-weight: bold;
  text-decoration: underline 3px #fdff00
}

.content-text-ac p {
  font-size: 1.8rem; /* Larger paragraph text for larger screens */
}

/* Responsive styles */
@media (max-width: 1024px) { 
  .promo-section-pg {
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding: 15px;
      margin: 20px; /* Margins on all sides, including right */
  }

  .roadmap-image-pg {
      width: 70%; /* Further reduce width */
      max-width: 250px;
      margin: 0 0 20px 0;
  }

  .promo-content-pg {
      max-width: 90%;
      margin: 0 10px; /* Added right margin */
  }

  .promo-content-pg h1 {
      font-size: 1.6rem; /* Further reduced font size */
  }

  .promo-content-pg p {
      font-size: 0.9rem;
  }

  .content-item-ac {
      flex-direction: column;
      text-align: center;
      gap: 10px;
  }

  .content-image-ac {
      width: 70%;
      max-width: 200px;
  }

  .content-text-ac {
      max-width: 80%;
      font-size: 1.3rem; /* Smaller font */
  }

  .content-text-ac h3 {
      font-size: 1.4rem;
  }

  .content-text-ac p {
      font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .promo-section-pg {
      
      width: 95%;
      padding: 8px;
        margin: 10px;
  }

  .promo-content-pg h1 {
      font-size: 1.4rem; /* Further reduced */
  }

  .promo-content-pg p {
      font-size: 0.8rem;
  }

  .content-item-ac {
      flex-direction: column;
      gap: 10px;
      text-align: center;
  }

  .content-item-ac {
    flex-direction: row;
    gap: 20px;
    text-align: left;
}

.content-image-ac {
    width: 40%;
    max-width: 250px;
}

.content-text-ac {
    max-width: 55%;
    font-size: 1.6rem;
}

.content-text-ac h3 {
    font-size: 1.6rem;
}

.content-text-ac p {
    font-size: 1.1rem;
}
}

@media (max-width: 480px) { 
  .promo-section-pg {
      padding: 5px;
      margin: 5px;
      width: 90%;
      box-shadow: none;
  }

  .promo-content-pg h1 {
      font-size: 1.2rem; 
  }

  .promo-content-pg p {
      font-size: 0.7rem; 
  }

  .content-item-ac {
      flex-direction: row;
      gap: 15px;
  }

  .content-image-ac {
      width: 40%;
      max-width: 200px;
  }

  .content-text-ac {
      max-width: 55%;
      font-size: 1.4rem;
  }

  .content-text-ac h3 {
      font-size: 1.4rem;
  }

  .content-text-ac p {
      font-size: 1rem;
  }

  .promo-buttons-pg {
      flex-direction: column;
      gap: 8px;
  }
  .roadmap-image-pg {
    width: 80%;
  }
  /* Horizontal Ask Box */
  .ask-form {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      padding: 10px;
      margin: 5px auto;
      max-width: 90%;
  }

  .ask-box {
      display: flex;
      flex-direction: row;  /* Horizontal layout */
      gap: 5px;
      width: 100%;
      align-items: center;
      margin-top: -70px;
      margin-bottom: -30px;
  }

  .ask-input {
      flex: 1;
      padding: 10px;
      font-size: 14px; 
      box-shadow: 0 0 6px rgba(255, 145, 0, 0.3);
      border: 1px solid #ccc;
      border-radius: 5px;
  }

  .ask-submit {
    margin-top: -45px;
      padding: 10px 15px;
      font-size: 14px;
      font-weight: bold;
      background-color: black;
      color: white;
      border: none;
      cursor: pointer;
      border-radius: 5px;
      transition: 0.3s;
  }

  .ask-submit:hover {
      background-color: #dcdc09;
  }
}


