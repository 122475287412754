.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: black;
  height: 550px;
  padding: 20px;
  color: white;
  margin-bottom: 30px;
  display: flex; /* Center contents horizontally */
  position: relative;
  margin-bottom: 80px;
}

.App-header::after {
  content: '';
  position: absolute;
  bottom: -60px; /* Push the curve down further */
  left: -50%; /* Extend the curve to the left */
  width: 200%; /* Extend the curve to cover the full width */
  height: 70px; /* Increase the height of the curve */
  background-color: black; /* Match the header background color */
  border-radius: 0 0 80% 80%; /* Adjust curvature */
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full height of the viewport */
}

.spinner {
  font-size: 10rem;
  color: #05192D;; /* You can customize the color */
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}



/*.circle-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #0d1b2a;
}
*/
/* src/CircleDesign.css 

.circle-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #0d1b2a;
  border-radius: 8px;
}
*/
.navbar {
  display: flex;
 /* Navbar styling */
}

.navbar ul {
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;
}

.navbar li {
  position: relative;
  margin-right: 20px;
}

.navbar .nav-link {
  text-decoration: none;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  padding: 10px 15px;
  border-radius: 5px; /* Rounded corners */
  transition: background-color 0.3s ease; /* Smooth background color transition */
}

.navbar .nav-link:hover {
  background-color: #555; /* Darker background on hover */
}

/* Dropdown styling */
.dropdown-content {
  display: none;
  position: absolute;
  background: linear-gradient(to bottom, white, #f0f0f0); /* Subtle gradient background */
  border-radius: 5px; /* Rounded corners */
  min-width: 200px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2); /* Shadow for depth */
  z-index: 1;
  transition: opacity 0.3s ease; /* Smooth transition for dropdown appearance */
}

.dropdown-content a {
  color: #333; /* Dark text color */
  padding: 12px 16px;
  text-decoration: none;
  display: flex;
  align-items: center;
  font-size: 14px;
  border-bottom: 1px solid #ddd; /* Light divider lines between items */
  transition: background-color 0.3s ease; /* Smooth background color transition */
}

.dropdown-content a:last-child {
  border-bottom: none; /* Remove border from the last item */
}

.dropdown-content a:hover {
  background-color: #e0e0e0; /* Light background on hover */
}

.dropdown:hover .dropdown-content {
  display: block;
  opacity: 1; /* Make dropdown visible */
}

.dropdown-content {
  opacity: 0; /* Initially hidden */
}


.roadmap-image {
  width: auto; /* Let the image maintain its original width */
  height: auto; /* Let the image maintain its original height */
  max-width: 100%; /* Optional: Ensure the image doesn’t overflow its container */
  max-height: 100%; /* Optional: Ensure the image doesn’t overflow its container */
}


.section {
  position: absolute;
  width: 80px;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
}

.section svg {
  font-size: 5rem; /* Increase from 2rem to 3rem or any other size you prefer */
  margin-bottom: 5px;
  margin-top: 5px;
}

.section.assess {
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  color:black;
  border-radius: 50%;
  background-color: rgb(6, 189, 252);
  
}
.section.learn {
  top: 50%;
  right: 0;
  transform: translate(50%, -50%);
  color:black;
  border-radius: 50%;
  background-color: #03EF62;
}

.section.practice {
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
 color:black;
  border-radius: 50%;
  background-color: rgb(255, 147, 30);
}

.section.apply {
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
  color:black;
  border-radius: 50%;
  background-color: rgb(255, 110, 169);
}


.App-title {
  font-size: 1.5em;
  margin-left: 20px;
}

.two-pages button {
  margin-left: 60px;
  margin-top: 400px;
  padding: 20px 40px; /* Further increased padding for larger buttons */
  font-size: 1.4em; /* Further increased font size */
  cursor: pointer; 
  background-color: #FFFBF3; /* Background color */
  border: none;
  color: #05192D; /* Text color */
  border-radius: 5px;
  transition: transform 0.3s, box-shadow 0.3s, color 0.3s;
  box-shadow: 0 5px #A5A5A5; /* 3D shadow effect */
  position: relative;
  overflow: hidden;
}

.two-pages button:active {
  box-shadow: 0 2px #A5A5A5; /* Shadow when button is pressed */
  transform: translateY(3px); /* Move button down when pressed */
}

.two-pages button::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color: #03EF62;/* Hover background color */
  transition: height 0.5s;
  z-index: 0;
}

.two-pages button:hover::before {
  height: 100%;
}

.two-pages button:hover {
  color: #000000; /* Text color on hover */
  box-shadow: 0 7px #8E8E8E; /* Deeper shadow on hover for a more pronounced 3D effect */
}

.two-pages button span {
  position: relative;
  z-index: 1;
}




.App-intro {
  font-size: large;
}

.header-buttons {
  position: absolute;
  top: 20px;
  right: 20px;
}

.header-buttons button {
  margin-left: 10px;
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
  background-color: #03EF62;
  border: none;
  color: #05192D;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.header-buttons button:hover {
  background-color: #45a049;
}

.header-buttons button:active {
  background-color: #3e8e41;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px); 
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  width: 500px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  animation: slide-down 0.3s ease-out;
  position: relative;
}

@keyframes slide-down {
  from {
    transform: translateY(-50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.close-button {
  background-color: red;
  border: none;
  color: white;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 1em;
  position: absolute;
  top: 10px;
  right: 10px;
}

.close-button:hover {
  background-color: darkred;
}

.tabs {
  display: flex;
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
}

.tab {
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.tab:hover {
  background-color: #f1f1f1;
}

.tab-active {
  font-weight: bold;
  border-bottom: 2px solid #000;
}

.form-group {
  margin-bottom: 15px;
  text-align: left;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1em;
}

.submit-button {
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 1em;
  transition: background-color 0.3s;
  width: 100%;
}

.submit-button:hover {
  background-color: #45a049;
}

.submit-button:active {
  background-color: #3e8e41;
}

.or-divider {
  display: flex;
  align-items: center;
  margin: 20px 0;
  position: relative;
}

.or-divider span {
  margin: 0 10px;
  font-size: 1.2em;
}

.or-divider::before,
.or-divider::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #ddd;
}

.social-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.social-button {
  background-color: #db4437; /* Google red */
  border: none;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 1em;
  transition: background-color 0.3s;
  flex: 1;
  margin: 0 5px;
}

.social-button.facebook {
  background-color: #3b5998; /* Facebook blue */
}

.social-button:hover {
  opacity: 0.9;
}

.forgot-password {
  margin-top: 10px;
  font-size: 0.9em;
}

.forgot-password a {
  color: #007bff;
  text-decoration: none;
}

.forgot-password a:hover {
  text-decoration: underline;
}
.input-icon {
  position: relative;
  display: flex;
  align-items: center;
}

.input-icon .icon {
  position: absolute;
  left: 10px;
  color: #ccc;
}

.input-icon input {
  padding-left: 30px;
}