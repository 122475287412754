.footer { 
  background-color: black;
  color: #fff;
  padding: 20px 0;
  display: flex;
  justify-content: center;
  flex-direction: column; /* Stack content vertically on smaller screens */
}

.footer-content {
  width: 90%;
  display: flex;
  flex-direction: column; /* Stack sections vertically on smaller screens */
  align-items: center; /* Center align the content */
}

.footer-section {
  margin-bottom: 20px; /* Add space between sections */
  text-align: center;
}

.footer-logo {
  width: 50px; /* Adjust size for smaller screens */
  margin-bottom: 10px;
  margin-left: 15px;
}

/* Style for links section with margin-left and gap */
.links {
  display: flex;
  flex-direction: column; /* Stack links vertically */
  gap: 10px; /* Add a gap between links */
  margin-left: 15px; /* Add margin-left for spacing */
}

.links a {
  color: #fff;
  text-decoration: none;
  font-size: 14px; /* Adjust font size for better readability */
}

.links a:hover {
  text-decoration: underline;
}

/* Style for the newsletter section */
.newsletter input {
  padding: 10px;
  margin-bottom: 10px;
  width: 100%; /* Full width on smaller screens */
  max-width: 300px; /* Limit maximum width */
  border: none;
  
}

.newsletter h3 {
  font-size: 16px; /* Adjust the font size */
}

.newsletter button {
  padding: 10px 20px;
  background-color: #03EF62;
  color: #05192D;
  border: none;
  cursor: pointer;
  width: 100%; /* Full width on smaller screens */
  max-width: 300px; /* Limit maximum width */
}
.footer-rights {
  text-align: center;
  margin-top: 10px;
  color: #fff;
  font-size: 10px;
 margin-right: 200px;
 
}

/* Layout adjustments for larger screens */
@media (min-width: 768px) {
  .footer-content {
    flex-direction: row; /* Row layout for larger screens */
    justify-content: space-between;
  }

  .footer-section {
    text-align: left;
    margin-bottom: 0; /* Remove margin on larger screens */
  }

  .links {
    flex-direction: row; /* Row layout for links on larger screens */
    gap: 40px; /* Increase gap between links */
    margin-left: 200px; /* Reset margin-left for larger screens */
  }

  .footer-logo {
    width: 50px; /* Adjust logo size for larger screens */
  }

  .newsletter h3 {
    font-size: 18px; /* Increase font size for larger screens */
  }

  .newsletter input,
  .newsletter button {
    width: auto; /* Adjust width for larger screens */
    max-width: none; /* Remove max-width constraint */
  }
}

/* Overlay and form container styling for pop-ups */
.form-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.form-container {
  background: #05192D;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
  position: relative;
  color: #03EF62;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #db4437;
  border: none;
  cursor: pointer;
  font-size: 18px;
}
