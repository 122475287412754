.ad-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px;
  background: linear-gradient(135deg, #3a0ca3, #7209b7, #f72585);
  transform: scale(1.02);
  border-radius: 20px;
  box-shadow: 0 8px 20px #00000026;
  max-width: 1000px;
  margin: 20px auto;
  border: 1px solid #ddd;
  transition: transform 0.3s ease;
  text-decoration: none;
}
.ad-container-link:hover {
  text-decoration: none; /* Ensure no underline on hover */
}
.ad-container.horizontal {
  flex-direction: row;
}

.ad-container.vertical {
  flex-direction: column;
  align-items: center;
  padding: 10px;
  height: auto;
  
}
.ad-container.vertical-link{
  text-decoration: none;
}

/* Content Styles */
.ad-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.ad-text-content {
  max-width: 60%;
  text-align: center;
}

.ad-title {
  font-size: 3.5rem;
  font-weight: bold;
  margin-bottom: 15px;
  display: flex; /* To handle spacing between emoji and text easily */
  align-items: center;
}

.ad-title .emoji {
  margin-right: 2px; 
  font-size: 45px;
  display: inline-block; /* Ensures transformations work properly */
  animation: bounce 1.5s infinite ease-in-out;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}


.ad-title .text {
  color: transparent; /* Transparent text for the main title */
  background-color: #e7ebea; /* Background color for clipped text */
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; /* Ensures background effect is applied to text */
}

.ad-detail ul {
  list-style-type: none;
  padding: 0;
}

.ad-detail li {
  margin: 8px 0;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  color: #fcfbff;
}

/* Button Styles */
.ad-buttons {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.ad-button {
  padding: 12px 26px;
  font-size: 1.5rem;
  font-weight: bold;
  background: linear-gradient(135deg, #00c853, #009688, #26c6da);
  color: white;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 5px 15px rgba(234, 58, 120, 0.3);
  text-transform: uppercase;
  letter-spacing: 1px;
}

.ad-button:hover {
  background: linear-gradient(135deg, #009688, #26c6da, #00c853);
  transform: translateY(-3px) scale(1.05);
  box-shadow: 0 8px 20px rgba(234, 58, 120, 0.4);
}

.ad-button:active {
  transform: translateY(1px) scale(0.98);
  box-shadow: 0 4px 10px rgba(234, 58, 120, 0.2);
}



/* Lottie Animation Styles */
.lottie-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 300px;
  height: 220px;
  background: linear-gradient(145deg, rgba(255, 255, 255, 0.9), rgba(200, 200, 200, 0.8));
  border-radius: 20px;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3), 0 -3px 8px rgba(255, 255, 255, 0.6) inset;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.lottie-wrapper:hover {
  transform: scale(1.2);
  box-shadow: 0 12px 25px rgba(0, 0, 0, 0.4), 0 -5px 10px rgba(255, 255, 255, 0.7) inset;
}

.lottie-animation {
  width: 100%;
  height: 100%;
  border: none;
}

/* Horizontal Styles */
.ad-container.horizontal .ad-content {
  flex-direction: row;
  gap: 20px;
 
}

.ad-container.horizontal .ad-text-content {
  text-align: left;
}

.ad-container.horizontal .lottie-wrapper {
  margin-left: auto;
}

/* Vertical Styles */
.ad-container.vertical .ad-content {
  flex-direction: column;
  gap: 10px;
  align-items: center;
 
}

.ad-container.vertical .ad-text-content {
  max-width: 85%;
  text-align: left;
}


.ad-container.vertical .ad-buttons {
  flex-direction: column;
  gap: 10px;
}

.ad-container.vertical .lottie-wrapper {
  margin: 0 auto;
  max-width: 220px;
  height: 180px;
  background: linear-gradient(145deg, rgba(245, 245, 245, 0.9), rgba(210, 210, 210, 0.8));
  border-radius: 15px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.25), 0 -2px 6px rgba(255, 255, 255, 0.4) inset;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.ad-container.vertical .lottie-wrapper:hover {
  transform: scale(1.1);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3), 0 -4px 8px rgba(255, 255, 255, 0.6) inset;
}

.ad-container.vertical .ad-title {
  font-size: 2.5rem;
  line-height: 1.2;
}

.ad-container.vertical .ad-detail li {
  font-size: 1.4rem;
  margin: 10px 0;
}
.vertical .ad-title .emoji {
  margin-right: 2px;
  margin-left: -25px; /* Add spacing between emoji and text */
}

.ad-container.vertical .ad-buttons .ad-button {
  width: 100%;
  text-align: center;
}
/* Responsive Styles */
@media (max-width: 768px) {
  .ad-container {
    padding: 10px;
    max-width: 90%;
    transform: scale(0.95);
  }

  .ad-title {
    font-size: 2.5rem;
    margin-bottom: 8px;
  }

  .ad-detail li {
    font-size: 1.2rem;
  }

  .ad-buttons .ad-button {
    font-size: 1.2rem;
    padding: 8px 15px;
  }

  .lottie-wrapper {
    width: 240px;
    height: 160px;
  }
}

@media (max-width: 480px) {
  .ad-container {
    padding: 8px;
    max-width: 95%;
    transform: scale(0.9);
  }

  .ad-title {
    font-size: 2rem;
    margin-bottom: 5px;
  }

  .ad-detail li {
    font-size: 1rem;
  }

  .ad-buttons .ad-button {
    font-size: 1rem;
    padding: 6px 12px;
  }

  .lottie-wrapper {
    width: 200px;
    height: 140px;
  }
}

