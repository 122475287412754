.header {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: black;
  padding: 10px 20px;
  height: 60px;
  z-index: 1000;
  transition: background-color 0.3s, padding 0.3s;
}

.header.scrolled {
  background-color: black;
  padding: 5px 20px;
}

.header-left {
  display: flex;
  align-items: center;
}

.logo {
  height: 30px;
  margin-right: 10px;
  margin-left: -10px;
}

.title {
  color: white;
  font-size: 16px;
}


.header-nav {
  flex-grow: 1;
  
}
.header-nav .dropdown span {
  color: white;
}
.dropdown-content {
  display: none;
  position: absolute;
  background: black; /* Subtle gradient background */
  border-radius: 5px; /* Rounded corners */
  min-width: 200px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2); /* Shadow for depth */
  z-index: 1;
  transition: opacity 0.3s ease; /* Smooth transition for dropdown appearance */
}

.dropdown-content a {
  color: #333; /* Dark text color */
  padding: 12px 16px;
  text-decoration: none;
  display: flex;
  align-items: center;
  font-size: 14px;
  border-bottom: 1px solid #ddd; /* Light divider lines between items */
  transition: background-color 0.3s ease; /* Smooth background color transition */
}

.dropdown-content a:last-child {
  border-bottom: none; /* Remove border from the last item */
}

.dropdown-content a:hover {
  background-color: #fff; 
  color:black;/* Light background on hover */
}

.dropdown:hover .dropdown-content {
  display: block;
  opacity: 1; /* Make dropdown visible */
}

.dropdown-content {
  opacity: 0; /* Initially hidden */
}
.header-nav ul {
  display: flex;
  justify-content: left;
  list-style: none;
  padding: 0;
  margin: 0;
}

.header-nav ul li {
  margin: 0 15px;
}

.header-nav ul li a {
  color: #fff;
  text-decoration: none;
  font-size: 14px;
}

.header-right {
  display: flex;
  gap: 10px;
}

.header-button {
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
  background-color: #fdff00;
  border: none;
  color: black;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.header-button:hover {
  background-color: #dcdc09;
}
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  width: 500px;
  max-width: 90%;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  animation: slide-down 0.3s ease-out;
  position: relative;
}

@keyframes slide-down {
  from {
    transform: translateY(-50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.close-button {
  background-color: red;
  border: none;
  color: white;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 1em;
  position: absolute;
  top: 10px;
  right: 10px;
}

.close-button:hover {
  background-color: darkred;
}

.tabs {
  display: flex;
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
  overflow-x: auto;
}

.tab {
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.tab:hover {
  background-color: #f1f1f1;
}

.tab-active {
  font-weight: bold;
  border-bottom: 2px solid #000;
}

.form-group {
  margin-bottom: 15px;
  text-align: left;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1em;
}

.submit-button {
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 1em;
  transition: background-color 0.3s;
  width: 100%;
}

.submit-button:hover {
  background-color: #45a049;
}

.submit-button:active {
  background-color: #3e8e41;
}

.or-divider {
  display: flex;
  align-items: center;
  margin: 20px 0;
  position: relative;
}

.or-divider span {
  margin: 0 10px;
  font-size: 1.2em;
}

.or-divider::before,
.or-divider::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #ddd;
}

.social-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.social-button {
  background-color: #db4437; /* Google red */
  border: none;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 1em;
  transition: background-color 0.3s;
  flex: 1;
  margin: 0 5px;
}

.social-button.facebook {
  background-color: #3b5998; /* Facebook blue */
}

.social-button:hover {
  opacity: 0.9;
}

.forgot-password {
  margin-top: 10px;
  font-size: 0.9em;
}

.forgot-password a {
  color: #007bff;
  text-decoration: none;
}

.forgot-password a:hover {
  text-decoration: underline;
}

.input-icon {
  position: relative;
  display: flex;
  align-items: center;
}

.input-icon .icon {
  position: absolute;
  left: 10px;
  color: #05192D;
}

.input-icon input {
  padding-left: 40px;
  color: #05192D;
}
.eye-icon {
  position: absolute;
  right: 10px; /* Adjust this to position it inside the input field */
  cursor: pointer;
  color: #888; /* Optional styling */
  font-size: 18px; /* Adjust size if necessary */
}
.user-info {
  display: flex;
  align-items: center;
  
}
.error-message {
  color: red;
  margin-top: 10px;
}

.user-initials {
  background-color: white; /* White background */
  color: black; /* Black text color */
  border-radius: 50%;
  width: 40px; /* Adjust size as needed */
  height: 40px; /* Adjust size as needed */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px; /* Adjust font size as needed */
  font-weight: bold;
  margin-right: 10px;
}

/* Media Queries for Responsiveness */

/* Media Queries for Responsiveness */
/* Media Queries for Responsiveness */

/* Media Queries for Responsiveness */

/* Media Queries for Responsiveness */

@media (max-width: 768px) {
  /* Make header items stack vertically */
  .header {
    flex-direction: column;
    align-items: flex-start;
  }

  .header-right {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px; /* Space between buttons and navigation links */
    margin-top:-40px
  }

  /* Move Sign In and Register buttons to the top-right side */
  .header-right {
    order: 1;
  }

  .header-nav {
    order: 2;
    width: 100%;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .header-nav ul {
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin: 0;
  }

  .header-nav ul li {
    margin: 0 10px;
  }

  .title {
    font-size: 14px;
    text-align: center;
  }

  .header-button {
    padding: 8px 16px;
    font-size: 0.9em;
  }

  /* Hide Register button on smaller devices if needed */
  .header-button.register {
    display: none;
  }
}

@media (max-width: 480px) {
  .header {
    flex-direction: column;
    height: auto;
    width:100%;
  }

  .header-left {
    margin-bottom: 10px;
  }

  .header-right {
    flex-direction: row;
    justify-content: flex-end;
    margin-top:-40px
  }

  .header-nav ul {
    flex-direction: row;
    align-items: center;
    
  }

  .header-nav ul li {
    margin: 5px 10px;
  }

  .header-button {
    font-size: 0.8em;
  }

  .social-buttons {
    flex-direction: column;
  }

  .social-button {
    margin-bottom: 10px;
  }
}


