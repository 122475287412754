/* General Styles */
.ad-top-container-link {
  display: flex;
  justify-content: center;
  text-decoration: none;
  width: 100%;
  margin: 2px 0;
}

.ad-top-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60%;
  background: linear-gradient(135deg, #3a0ca3, #7209b7, #f72585);
  color: white;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
}

.ad-top-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
}

/* Button Styles */
.ad-top-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.ad-top-button {
  background: linear-gradient(135deg, #00c853, #009688, #26c6da);
  color: white;
  padding: 12px 22px;
  border-radius: 15px;
  font-weight: bold;
  cursor: pointer;
  font-size: 14px;
  box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.2), inset -2px -2px 5px rgba(255, 255, 255, 0.2);
  transition: all 0.3s ease;
  border: none;
  text-align: center;
}

.ad-top-button:hover {
  background: linear-gradient(135deg, #009688, #26c6da, #00c853);
  box-shadow: 0 8px 20px rgba(234, 58, 120, 0.4);
}

/* Lottie Animation */
.ad-lottie-wrapper {
  width: 150px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.ad-lottie-animation {
  width: 100%;
  height: 100%;
  transform: scale(2.5);
}

/* Title */
.ad-title {
  font-size: 22px;
  line-height: 1.4;
  text-align: center;
}
@media (max-width: 768px) {
  .ad-top-container {
    width: 90%;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }

  .ad-top-content {
    flex-direction: column;
    gap: 12px;
  }

  .ad-title {
    font-size: 16px;
  }

  .ad-top-button {
    padding: 8px 15px;
    font-size: 11px;
    border-radius: 10px;
  }

  .ad-lottie-wrapper {
    width: 90px;
    height: 60px;
  }

  .ad-lottie-animation {
    transform: scale(1.8);
  }
}
/* ✅ Smaller screens (max-width: 480px) – All elements smaller */
@media (max-width: 480px) {
  .ad-top-container {
    width: 95%;           /* Full width for mobile */
    padding: 4px;         /* Smaller padding */
    border-radius: 5px;  
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
    
  }

  .ad-top-content {
    flex-direction:row; 
    gap: 3px;              /* Smaller gap */
  }

  .ad-title {
    font-size: 8px;        /* Smaller text */
     
  }
.emoji{
  font-size: 7px;
  margin-left:-10px;
}
  .ad-top-button {
    padding: 3px 6px;      /* Smaller button */
    font-size: 10px;        /* Smaller font size */
    border-radius: 8px;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
  }

  .ad-lottie-wrapper {
    width: 50px;            /* Smaller Lottie animation */
    height: 20px;
  }

  .ad-lottie-animation {
    transform: scale(1.4);  /* Smaller animation scaling */
  }
}

